<template>
  <div>
    <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-square-pin"></i> Beranda
      </h6>
    </h1>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7"> </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col xl="12">
          <b-card>
            <!-- <h1>(User Titik Penaatan) - (Admin Lengkap)</h1>
            <p>Sebelum Login cuman ada data Beban</p> -->


                <b-row>
                  <b-col xl="12" md="12">
                    <b-card style="position: relative;overflow: hidden;box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);">

                      <h3>Cari Berdasarkan</h3>

                      <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">

                        <!-- Jenis Industri -->
                        <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                          <label><small>Jenis Industri</small></label>
                          <base-input>
                            <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType">
                              <el-option value="">Semua Jenis Industri</el-option>
                              <el-option
                                v-for="option, idx in provideSelect.companyType"
                                :key="idx"
                                :label="option.name"
                                :value="option.id"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </div>

                        <!-- Provinsi -->
                        <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                          <label><small>Provinsi</small></label>
                          <base-input name="Provinsi" >
                              <el-select
                                v-model="form.umum.compProvince"
                                @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                                <el-option
                                label="Semua Provinsi"
                                :value=null
                                >
                                </el-option>
                                <el-option
                                  v-for="option in provideSelect.provinsi"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                          </base-input>
                        </div>

                        <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                          <label><small>Kabupaten / Kota</small></label>
                          <base-input name="Kabupaten / Kota" >
                              <el-select
                                v-model="form.umum.compCity"
                                >
                                <el-option
                                  v-for="option in provideSelect.kabupatenKota"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                          </base-input>
                        </div>

                        <!-- Kabupaten kota -->
                        <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                          <label><small>Kabupaten / Kota</small></label>
                          <base-input name="Kabupaten / Kota">
                            <select
                              class="form-control"
                              v-model="form.umum.compCity"
                            >
                              <option
                                v-for="(option, idx) in provideSelect.kabupatenKota"
                                :key="idx"
                                :value="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                          </base-input>
                        </div> -->

                        <!-- Nama Industri -->
                        <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" >
                          <label><small>Nama Industri</small></label>
                          <base-input name="Nama Industri" >
                              <el-select
                                v-model="userInput.companyName"
                                @change="getMyCompliencePoint"
                                :disabled="log_as !== 'admin'"
                                >
                                <el-option value="">Semua</el-option>
                                <el-option
                                  v-for="option in provideSelect.companyName"
                                  :key="option.id"
                                  :label="option.name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                          </base-input>
                        </div>

                        

                      

                        <!-- Titik Penaatan -->
                        <div style="display: flex;flex-direction: column;width: 180px;">
                          <label><small>Titik Penaatan</small></label>
                          <base-input>
                              <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                                <el-option value="">Semua</el-option>
                                <el-option
                                  v-for="option in listDataCompliencePoints"
                                  :key="option.id"
                                  :label="option.spot_name"
                                  :value="option.id"
                                ></el-option>
                              </el-select>
                          </base-input>
                        </div>
                        
                        
                      </div>

                      <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
                      <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
                    </b-card>
                  </b-col>
                </b-row>





            <div id="map-customx" class="map-canvas"></div>
          </b-card>
        </b-col>
      </b-row>

      <!-- <div class="parameter row flex-row overflow-auto flex-nowrap">
        <div
          v-for="item in [0, 0, 0, 0, 0]"
          :key="item.device_name"
          class="col-1-5"
        >
          <div class="card">
            <h4
              class="row align-items-center justify-content-center text-primary font-weight-light m-0"
              style="height: 3rem"
            >
              {{ "?" }}
            </h4>
            <hr class="m-0" />
            <h2
              class="row align-items-center justify-content-center text-primary font-weight-bolder m-0"
              style="height: 5rem"
            >
              <span>{{ "?" }}</span>
            </h2>
          </div>
        </div>
        <div style="min-width: 15px"></div>
      </div> -->
    </b-container>

    <!-- modal Melebihi Baku Mutu -->
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="summary-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <p>Notifikasi Melebihi Baku Mutu</p>
        </div>
      </template>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentsannouncement-desc"
        >
         
          <div  style="height: 400px;overflow-x: scroll;">
            <!-- <EarlyWarningJamTable :tableData="listDataAlert" /> -->
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Industri</th>
                  <th>Jenis Industri</th>
                  <th>Provinsi</th>
                  <th>Kabupaten / Kota</th>
                  <th>Parameter</th>
                  <th>Surat Teguran</th>
                  <th>Tanggal</th>
                </tr>
              </thead>
              <tbody> 
                <tr v-for="(item, index) in earlywarning.items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.company_name }}</td>
                  <td>{{ item.company_type_name }}</td>
                  <td>{{ item.province_name }}</td>
                  <td>{{ item.regency_name }}</td>
                  <td class="text-danger"><b>{{ item.message }}</b></td>
                  <td><a href="https://sparingengine.ppkl.menlhk.go.id/storage/S.140%20(Peringatan%20data%20melebihi%20baku%20mutu%20air%20limbah).pdf" target="blank"><button class="btn btn-primary btn-sm">Surat Teguran</button></a></td>
                  <td>{{ formatDate(item.timestamp) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              @click="$router.push('/earlywarning/jam')"
              class="bg-white text-primary border-0"
              >Ke Laporan Early Warning</base-button
            >
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- modal map -->
    <Modal
      :show="showAnnoncementModalDescription"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showAnnoncementModalDescription = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="modal-title">
          <table class="table table-striped">
            <tr>
              <th>Nama Titik Penaatan</th>
              <th>:</th>
              <th>{{description.compliance_point_name}}</th>
            </tr>
            <tr>
              <th>LoggerID</th>
              <th>:</th>
              <th>{{ 'description.loggerId' }}</th>
            </tr>
          </table>
        </div>
      </template>
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentsannouncement-desc">
          <div >
            <div>
              <div style="overflow-x: scroll;">
                <table class="table " >
                  <thead>
                    <tr>
                      <th>Nama Industri</th>
                      <th>Jenis Industri</th>
                      <th>Provinsi</th>
                      <th>Kabupaten / Kota</th>
                      <th>Alamat</th>
                      <th>Parameter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ description.name }}</td>
                      <td>{{ description.type }}</td>
                      <td>{{ description.province }}</td>
                      <td>{{ description.regency }}</td>
                      <td>{{ description.address || "-" }}</td>
                      <td>{{ description.parameter }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr>

              <div  class="card">
                 <table class="table">
                      <thead>
                        <tr>
                          <th>BEBAN COD</th>
                          <th>BEBAN TSS</th>
                          <th>BEBAN NH3N</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ description.beban_cod }}</td>
                          <td>{{ description.beban_tss }}</td>
                          <td>{{ description.beban_nh3n }}</td>
                        </tr>
                      </tbody>
                    </table>
              </div>

              <hr>

              <div>
                <b-tabs pills>
                  <b-tab active>
                    <template v-slot:title>
                      <i class="ni ni-bell-55 mr-2"></i> ALL
                    </template>
                    <div class="p-3 card mt-3" >
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Ph</th>
                            <th>COD</th>
                            <th>TSS</th>
                            <th>Debit</th>
                            <th>NH3N</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ description.ph }}</td>
                            <td>{{ description.cod }}</td>
                            <td>{{ description.tss }}</td>
                            <td>{{ description.debit }}</td>
                            <td>{{ description.nh3n }}</td>
                          </tr>
                        </tbody>
                      </table>
                      
                      <hr>
                      
                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions"
                            :series="chartSeries"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>COD:</b></small> {{ chartSeries[1] }}%</li>
                              <li><small><b>TSS:</b></small> {{ chartSeries[2] }}%</li>
                              <li><small><b>NH3N:</b></small> {{ chartSeries[3] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions"
                            :series="chartSeries"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>COD:</b></small> {{ chartSeries[1] }}%</li>
                              <li><small><b>TSS:</b></small> {{ chartSeries[2] }}%</li>
                              <li><small><b>NH3N:</b></small> {{ chartSeries[3] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
  
                  <b-tab>
                    <template v-slot:title>
                      <i class="ni ni-bell-55"></i> PH
                    </template>
                    <div class="p-3 card mt-3">
                      <h2>PH : {{ description.ph }}</h2>
                      
                      <hr/>

                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesCOD"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>PH:</b></small> {{ chartSeriesCOD[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesPH"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>PH:</b></small> {{ chartSeriesPH[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
  
                  <b-tab>
                    <template v-slot:title>
                      <i class="ni ni-bell-55 mr-2"></i> COD
                    </template>
                    <div class="p-3 card mt-3">
                      <h2>COD : {{ description.cod }}</h2>
                     
                      
                      <hr/>
                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesDEBIT"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>COD:</b></small> {{ chartSeriesDEBIT[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesCOD"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>COD:</b></small> {{ chartSeriesCOD[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </div>
                  </b-tab>
  
                  <b-tab>
                    <template v-slot:title>
                      <i class="ni ni-bell-55 mr-2"></i> TSS
                    </template>
                    <div class="p-3 card mt-3">
                      <h2>TSS : {{ description.tss }}</h2>
                      
                      
                      <hr/>
                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesNH3N"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>TSS:</b></small> {{ chartSeriesNH3N[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesTSS"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>TSS:</b></small> {{ chartSeriesTSS[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
  
                  <b-tab>
                    <template v-slot:title>
                      <i class="ni ni-bell-55 mr-2"></i> NH3N
                    </template>
                    <div class="p-3 card mt-3">
                      <h2>NH3N : {{ description.nh3n }}</h2>
                      
                      
                      <hr/>
                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesCOD"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>NH3N:</b></small> {{ chartSeriesCOD[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesNH3N"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>NH3N:</b></small> {{ chartSeriesNH3N[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
  
                  <b-tab>
                    <template v-slot:title>
                      <i class="ni ni-bell-55 mr-2"></i> DEBIT
                    </template>
                    <div class="p-3 card mt-3">
                      <h2>DEBIT : {{ description.debit }}</h2>
                      
                      
                      <div style="display: flex;justify-content: space-around;">
                        <div class="chart-container  m-2">
                          <div class="header">
                            <h2 class="text-white">Data Masuk</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesTSS"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>DEBIT:</b></small> {{ chartSeriesTSS[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                        
                        <div class="chart-container m-2">
                          <div class="header">
                            <h2 class="text-white">Data Ketaatan</h2>
                          </div>
                          <apexchart
                            type="radialBar"
                            :options="chartOptions2"
                            :series="chartSeriesDEBIT"
                          ></apexchart>
                          <div class="legend">
                            <ul>
                              <li><small><b>DEBIT:</b></small> {{ chartSeriesDEBIT[0] }}%</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
  
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-danger border-0"
              @click="showAnnoncementModalDescription = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- modal Uji Kalibrasi -->
    <Modal
      :show="showAnnoncementUjiKalibrasiModal"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementUjiKalibrasiModal = false"
      size="lg"
    >
          <h3 class="text-center">Pemberitahuan Kalibrasi Sensor</h3>
          <hr>
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden"
        >
          <div>
            <!-- <EarlyWarningJamTable :tableData="listDataAlert" /> -->
            <p>Kami Informasikan bahwa sensor telah memasuki jadwal kalirbrasi. Detail jadwal Kalibrasi sebagai berikut</p>
              <table class="mb-3">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in listDataSensorExpired" :key="idx">
                    <td><b>{{ item.sensor_type }} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></td>
                    <td>{{ item.tanggal_berakhir_kalibrasi }}</td>
                  </tr>
                </tbody>
              </table>
            <p>Kami himbau untuk segera melakukan kalibrasi sensor agar data yang dikirim tetap akaurat untuk melakukan pembaharuan data kalibrasi, dapat mengunjungi <b>tautan</b> ini.</p>
            <p>Terimakasih atas perhatian dan kerja sama yang baik.</p>
            <p>* Notifikasi ini dapat di abaikan ketika sudah melaporkan uji Kalibrasi secara berkala</p>

          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              @click="$router.push('uji-kalibrasi')"
              class="bg-secondary border-0"
              >Lihat Uji Kalibrasi</base-button
            >
            <base-button
              class="bg-danger border-0"
              @click="showAnnoncementUjiKalibrasiModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import BaseHeader from "@/components/BaseHeader";
import { API_KEY } from "@/views/Maps/API_KEY";
import API from "../../api/base_url";
import EarlyWarningJamTable from "../Tables/EarlyWarningJamTable.vue";
import GoogleMapsLoader from "google-maps";
GoogleMapsLoader.KEY = API_KEY;
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueApexCharts from 'vue-apexcharts'
// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import { Select, Option, Table, TableColumn } from "element-ui";
import moment from "moment";

import {payloadMaps} from "../../dummyAPI/payloadMaps.js"
import axios from 'axios'

export default {
  components: {
    BaseHeader,
    Modal,
    flatPicker,
    EarlyWarningJamTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    apexchart: VueApexCharts,
  },
  created() {
    this.log_as = localStorage.getItem('as')
    
    // this.getSensorExpiredCalibration()
  },
  mounted() {
    this.getMyMaps(); 
    this.getCompanyType()
    this.getProvince()
    this.getCompanyName()
    this.getCompliencePoint()

    // this.chartSeriesPH = this.dummyPercentage()
    // this.chartSeriesCOD = this.dummyPercentage()
    // this.chartSeriesTSS = this.dummyPercentage()
    // this.chartSeriesNH3N = this.dummyPercentage()
    // this.chartSeriesDEBIT = this.dummyPercentage()

    // this.getEarlyWarning()
    
  },
  data() {
    return {
      earlywarning: [],
      pdfName:"",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val:0,
          name:"Menunggu"
        },
        {
          val:1,
          name:"Disetujui"
        },
        {
          val:2,
          name:"Ditolak"
        },
      ],
      userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Minggu",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Bulan",
                  attributes:{
                    class:"btn w-75 btn-dark mb-5 btn-sm"
                  }
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes:{
                    class:"btn w-100 btn-danger btn-sm"
                  }
                },
              ],
              onClick:(index,fp)=>{
                fp.clear();

                if(index == 0){
                const startDate = moment().startOf('day');
                  const endDate = moment(startDate).endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                  
                }else if(index == 1){
                  const startDate = moment().startOf('day');
                  const endDate = moment(startDate).add(1, 'week').endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);

                 
                }else if(index == 2){
                  const startDate = moment().startOf('month');
                  const endDate = moment().endOf('month');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              }
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal:false,
      showDownloadModalCSV:false,
      // end of filter
      log_as: "",
      mapData: {},
      listData: [],
      listDataSensorExpired: [],
      listDataAlert: [],
      showAnnoncementModal: false,
      showAnnoncementUjiKalibrasiModal: false,
      showAnnoncementModalDescription: false,
      description: {
        compliance_point_name: '',
        loggerId:"",
        name: "",
        type:"",
        address: "",
        province: "",
        regency: "",
        parameter: "",
        ph:"",
        cod:"",
        nh3n:"",
        debit:"",
        tss:"",
        beban_cod:"",
        beban_tss:"",
        beban_nh3n:""
      },
      chartOptions: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "16px",
              },
              value: {
                fontSize: "22px",
              },
              total: {
                show: true,
                label: "Total Data",
                color: "#333",
                fontSize: "16px",
                formatter: function (w) {
                  // Menghitung total dari semua nilai series
                  const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return total.toFixed(2);
                },
              }
            },
          },
        },
        labels: ["pH", "COD", "TSS", "NH3N", "Debit"],
        responsive: [
          {
            breakpoint: undefined,
            options: {
              radialBar: {
                offsetY: 0,
                offsetX: 0,
                size: undefined,
                hollow: {
                  margin: 5,
                  size: "30%",
                  background: "transparent",
                  image: undefined,
                },
                track: {
                  show: true,
                  startAngle: undefined,
                  endAngle: undefined,
                  background: "#f2f2f2",
                  strokeWidth: "97%",
                  opacity: 1,
                  margin: 5,
                },
              },
            },
          },
        ],
      },
      chartSeries: [50, 20, 30, 40, 50], 
      // Another Chart
      chartOptions2: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "16px",
              },
              value: {
                fontSize: "22px",
              },
              total: {
                show: true,
                label: "Total Data",
                color: "#333",
                fontSize: "16px",
                formatter: function (w) {
                  // Menghitung total dari semua nilai series
                  const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return total.toFixed(2);
                },
              }
            },
          },
        },
        labels: ["pH"],
        responsive: [
          {
            breakpoint: undefined,
            options: {
              radialBar: {
                offsetY: 0,
                offsetX: 0,
                size: undefined,
                hollow: {
                  margin: 5,
                  size: "30%",
                  background: "transparent",
                  image: undefined,
                },
                track: {
                  show: true,
                  startAngle: undefined,
                  endAngle: undefined,
                  background: "#f2f2f2",
                  strokeWidth: "97%",
                  opacity: 1,
                  margin: 5,
                },
              },
            },
          },
        ],
      },
      chartSeriesPH: [50], 
      chartSeriesCOD: [50], 
      chartSeriesTSS: [50], 
      chartSeriesNH3N: [50], 
      chartSeriesDEBIT: [50], 
    };
  },
  methods: {
    getProvince() {
    API.get(`provinces`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
       .then(({data:content}) => {
        this.userInput.titikPenaatan = ""
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    getEarlyWarning() {
      this.isLoading = true;
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`early-warning/hourly`, { headers })
        .then(({ data }) => {
          if (data.data !== null) {
            console.log(data.data,'--->?');
            this.earlywarning = data.data;
            this.showAnnoncementModal = true
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    dummyPercentage() {
      var randomValue = Math.random();
      // Mengalikan nilai acak dengan 100 untuk mendapatkan nilai antara 0 dan 100
      var scaledValue = Math.floor(randomValue * 100);
      
      // Membulatkan nilai ke puluhan
      var tensValue = Math.floor(scaledValue / 10) * 10;
      
      return [tensValue];
    },
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        status:this.filterStatusPendaftaran,
        start_date: startDate,
        end_date:endDate,
        province_id:this.form.umum.compProvince,
        regency_id:this.form.umum.compCity,
        company_id:this.userInput.companyName,
        company_type_id:this.userInput.companyType,
        sparing_compliance_point_id:this.userInput.titikPenaatan
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        // API.get(`dashboard/maps` , { params, headers})
        //   .then(({data}) => {
        //     if (data.data == null) {
        //     this.mapData = [];
        //   } else {
        //     this.mapData = data.data;
        //   }

       

        //   let dummycenter_loc = {
        //     lat: "-2.4833826",
        //     lng: "117.8902853",
        //   }

        //   if (this.log_as !== 'admin') {
        //     if (data.data !== null) {
        //       dummycenter_loc = {
        //         lat: data.data[0].lintang,
        //         lng: data.data[0].bujur,
        //       };
        //     }
        //   } 

        //   GoogleMapsLoader.load((google) => {
        //     this.initMap(google, dummycenter_loc);
        //   });
        //   })
        //   .catch((err) => {
        //     this.isLoading = false
        //     console.log(err);
        //   })
    },
    downloadPDFNow() {
      this.getDataDownload()
      this.showDownloadModal = true      
    },
    downloadExcelNow() {
      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    getCompanyProfile() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/me` , {headers})
          .then(({data}) => {
            if (data.data == null) {

            } else {
              this.companyMe = data.data

              if (this.log_as !== 'admin') {
                this.userInput.companyName = data.data.company.id
                this.pdfName = data.data.company.name
              }
            }
           
          })
          .catch((err) => {
            console.log(err);
        })
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comp-types', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('comps', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`kabkots?prov_id=${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`stations` , {headers})
      .then(({data:content}) => {
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    
    getBaseOnStatus() {
    
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const queryParams = {
      // status:this.filterStatusPendaftaran,
      province_id:this.form.umum.compProvince,
      regency_id:this.form.umum.compCity,
      company_id:this.userInput.companyName,
      company_type_id:this.userInput.companyType,
      sparing_compliance_point_id:this.userInput.titikPenaatan
    };


     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    // API.get(`dashboard/maps` , { params, headers})
    //   .then(({data}) => {
    //     if (data.data == null) {
    //         this.mapData = [];
    //       } else {
    //         this.mapData = data.data;


    //         let timerInterval;
    //         Swal.fire({
    //           title: "Menyiapkan Data",
    //           html: "",
    //           timer: 1000,
    //           timerProgressBar: true,
    //           didOpen: () => {
    //             Swal.showLoading();
    //             const timer = Swal.getPopup().querySelector("b");
    //             timerInterval = setInterval(() => {
    //               timer.textContent = `${Swal.getTimerLeft()}`;
    //             }, 100);
    //           },
    //           willClose: () => {
    //             clearInterval(timerInterval);
    //           }
    //         })
    //       }

       

    //       let dummycenter_loc = {
    //         lat: "-2.4833826",
    //         lng: "117.8902853",
    //       }

    //       if (this.log_as !== 'admin') {
    //         if (data.data !== null) {
    //           dummycenter_loc = {
    //             lat: data.data[0].lintang,
    //             lng: data.data[0].bujur,
    //           };
    //         }
    //       } 

    //       GoogleMapsLoader.load((google) => {
    //         this.initMap(google, dummycenter_loc);
    //       });
    //   })
    //   .catch((err) => {
    //     this.isLoading = false
    //     console.log(err);
    //   })
    },
    print() {
      var element = document.getElementById("printBos");
      var opt = {
        margin: 0.5,
        filename: "myfile.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      }
      // New Promise-based usage:
      html2pdf().set(opt).from(element).save();
      Swal.fire('Data di Unduh','','success')
      this.showDownloadModal = false
    },
    ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModalCSV = false
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
            XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
    },
    reportTableState() {
        return []
    },
   // End of Filter
    getSensorExpiredCalibration() {
      if (this.log_as == 'admin') {

      } else {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        API.get(`companies/sparings/compliance-points/sensor/expired-kalibrasi`,{ headers })
          .then(({ data }) => {
            if (data.data !== null) {
              this.listDataSensorExpired = data.data
    
              this.showAnnoncementUjiKalibrasiModal = true
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }


    },
    // ===================================== MAPS BUILD
    getMyMaps() {
      let tes = {
        "zoom": 5,
        "centerLoc": {
            "lat": -2.4833826,
            "lng": 117.8902853
        },
        comp: [
          {
              "_id": "5f572c1d36b37d2585a64c54",
              "compName": "PT Multi Harapan Utama",
              "compAddress": "Jl. Yos Sudarso RT. 17, Dusun IV, Desa Loa Kulu Kota, Kec. Loa Kulu, Kab. Kutai Kartanegara, Kalimantan Timur",
              "latlong": {
                  "lat": -0.536822222222222,
                  "lng": 117.032138888889
              },
              "kabkotName": "Kabupaten Kutai Kartanegara",
              "provName": "Kalimantan Timur",
              "activationLogger": "Terkoneksi",
              "parameterLogger": "pH,,TSS,,Debit",
              "titikPenaatan": "",
              "beban_sensor": [
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "COD",
                      "calculation": 0
                  },
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "TSS",
                      "calculation": 0.8053492445069356
                  },
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "NH3N",
                      "calculation": 0
                  }
              ]
          },
          {
              "_id": "5f572c1d36b37d2585a64c54",
              "compName": "PT Sejahtera tapi kurang Sejahtera",
              "compAddress": "Jl. Yos Sudarso RT. 17, Dusun IV, Desa Loa Kulu Kota, Kec. Loa Kulu, Kab. Kutai Kartanegara, Kalimantan Timur",
              "latlong": {
                  "lat": -0.636822222222222,
                  "lng": 110.032138888889
              },
              "kabkotName": "Kabupaten Kutai Kartanegara",
              "provName": "Kalimantan Timur",
              "activationLogger": "Tidak Terkoneksi",
              "parameterLogger": "pH,,TSS,,Debit",
              "titikPenaatan": "",
              "beban_sensor": [
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "COD",
                      "calculation": 0
                  },
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "TSS",
                      "calculation": 0.8053492445069356
                  },
                  {
                      "_id": "65ede717e18a7a560d399ce9",
                      "name": "NH3N",
                      "calculation": 0
                  }
              ]
          },
        ]
      }

      let setting = {
        "zoom": 5,
        "centerLoc": {
            "lat": -2.4833826,
            "lng": 117.8902853
        }
      }

      
      // this.mapData = payloadMaps;

      

      // this.initMap(setting.centerLoc);

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("beranda/map", {headers})
        .then(({data}) => {

          let newPayload = []

          for (let i = 0; i < data.data.length; i++) {
            newPayload.push({
              "id": data.data[i].station_id,
              "compName": "PT Multi Harapan Utama",
              "latlong": {
                  "lat": data.data[i].latitude,
                  "lng": data.data[i].longitude
              },
              "activationLogger": "Terkoneksi",
            })
          }

          console.log(newPayload,'-->');

          let newFormat = {
            zoom: 5,
            centerLoc: {
                lat: -2.4833826,
                lng: 117.8902853
            },
            comp:newPayload
          }

          console.log(newFormat);

          this.mapData = newFormat;
          this.initMap(setting.centerLoc);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire('Gagal mendapatkan Maps','Maaf, Terjadi Kesalahan', 'error')
        })

      // API.get("beranda/map", {headers})
      //   .then(({ data }) => {
      //     if (data.data == null) {
      //       this.mapData = [];
      //     } else {
      //       this.mapData = data.data;
      //     }

       

      //     let dummycenter_loc = {
      //       lat: "-2.4833826",
      //       lng: "117.8902853",
      //     }

      //     if (this.log_as !== 'admin') {
      //       if (data.data !== null) {
      //         dummycenter_loc = {
      //           lat: data.data[0].lintang,
      //           lng: data.data[0].bujur,
      //         };
      //       }
      //     } 

      //     GoogleMapsLoader.load((google) => {
      //       this.initMap(google, dummycenter_loc);
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    CenterControl(controlDiv, map, state) {
      // Set CSS for the control border.
      var controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#dd2c00";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.margin = "10px";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to recenter the map";
      controlDiv.appendChild(controlUI);

      // Set CSS for the control interior.
      var controlText = document.createElement("div");
      controlText.style.color = "#fff";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "40px";
      controlText.style.paddingLeft = "16px";
      controlText.style.paddingRight = "16px";
      controlText.textContent = "Reset Map";
      controlUI.appendChild(controlText);

      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", function () {
        state.resetMap();
        // map.setCenter(chicago);
      });
    },
    resetMap() {
      this.initMap(this.mapData.centerLoc);
    },
    async initMap(centerMap) {
      let L = require('leaflet');
      let map,
            lat = centerMap.lat,
            lng = centerMap.lng,
            color = "#5e72e4";
        map = document.getElementById("map-customx")
     

        let centerLoc = [lat, lng];
        let zoomLevel = this.mapData.zoom;

        
        map = L.map(map, {
            scrollWheelZoom: false
        }).setView(centerLoc, zoomLevel);
       

       
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        let mcg = L.markerClusterGroup({
          chunkedLoading: true,
          spiderfyOnMaxZoom: false,
          iconCreateFunction: function (cluster) {
              let childCount = cluster.getChildCount();
              let c = ' marker-cluster-';
              if (childCount < 10) {
                  c += 'small';
              } else if (childCount < 100) {
                  c += 'medium';
              } else {
                  c += 'large';
              }
              // Ganti ini dengan warna latar belakang yang Anda inginkan
              return new L.DivIcon({
                  html: '<div style="background-color: #618264;color:white;font-weight:bold; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;"><span>' + childCount + '</span></div>',
                  className: 'marker-cluster' + c,
                  iconSize: new L.Point(60, 60)
              });
          }
        });
        // Data marker
        let markerComp = this.mapData.comp;

        this.description = {
          compliance_point_name: '',
          loggerId:"",
          name: "",
          type:"",
          address: "",
          province: "",
          regency: "",
          parameter: "",
          ph:"",
          cod:"",
          nh3n:"",
          debit:"",
          tss:"",
          beban_cod:"",
          beban_tss:"",
          beban_nh3n:""
        }

        for (let k = 0; k < markerComp.length; k++) {
          if (markerComp[k].latlong.lat) {
              let posComp = [markerComp[k].latlong.lat, markerComp[k].latlong.lng];
              let iconUrl = markerComp[k].activationLogger != "Terkoneksi" ? require('../../../public/img/icons/map/marker-icon-2x-red.png') : require('../../../public/img/icons/map/marker-icon-2x-green.png');
              let icon = L.icon({
                  iconUrl: iconUrl,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  tooltipAnchor: [16, -28],
                  shadowSize: [41, 41]
              });

              let marker = L.marker(posComp, { icon: icon });

              
              
              
              // Memisahkan nama parameter dari parameterLogger yang dipisahkan oleh koma
              // let parameterNames = markerComp[k].parameterLogger.split(",").filter(param => param.trim() !== ""); 
              // Filter untuk menghapus nilai yang kosong

              // Membuat daftar nama parameter yang dimiliki oleh beban_sensor
              // let bebanSensorNames = markerComp[k].beban_sensor.map(sensor => sensor.name);

              // Menemukan nama-nama parameter yang dimiliki oleh kedua daftar
              // let commonParameterNames = parameterNames.filter(param => bebanSensorNames.includes(param));

              // let parameterString = parameterNames.join(", ");


              

              // Membuat tabel HTML dari nama parameter yang sama dan hasil perhitungan beban sensor
              // let sensorTable = "<table class='table table-striped'><tr><th>Nama Sensor</th><th>Hasil Perhitungan</th></tr>";
              // markerComp[k].beban_sensor.forEach(sensor => {
              //     if (commonParameterNames.includes(sensor.name)) {
              //         sensorTable += `<tr><td>${sensor.name} KG/Jam </td><td>${(sensor.calculation ? sensor.calculation.toFixed(4) : sensor.calculation)}</td></tr>`;
              //     }
              // });
              // sensorTable += "</table>";

              // let popupContent = `<div id="content">
              //           <div id="siteNotice"></div>
              //           <h3 id="firstHeading" class="firstHeading">${markerComp[k].compName}</h3>
              //           <div id="bodyContent">
              //               <table>
              //                 <tr>
              //                     <td style="text-align:left; width:30%;"><b>Alamat</b></td>
              //                     <td style="vertical-align: middle;">${markerComp[k].compAddress}</td>
              //                 </tr>
              //                 <tr>
              //                     <td style="text-align:left;"><b>Kabupaten/Kota</b></td>
              //                     <td style="vertical-align: middle;">${markerComp[k].kabkotName}</td>
              //                 </tr>
              //                 <tr>
              //                     <td style="text-align:left;"><b>Provinsi</b></td>
              //                     <td style="vertical-align: middle;">${markerComp[k].provName}</td>
              //                 </tr>
              //                 <tr>
              //                     <td style="text-align:left;"><b>Status</b></td>
              //                     <td style="vertical-align: middle;">${markerComp[k].activationLogger}</td>
              //                 </tr>
              //                 <tr>
              //                     <td style="text-align:left;"><b>Parameter</b></td>
              //                     <td style="vertical-align: middle;">${parameterString}</td>
              //                 </tr>
              //                 <tr>
              //                     <td style="text-align:left;"><b>Beban Sensor</b></td>
              //                     <td style="vertical-align: middle;">${sensorTable}</td>
              //                 </tr>
              //               </table>
              //           </div>
              //       </div>`;

              // marker.bindPopup(popupContent);

              marker.addEventListener('click', () => {
                  //marker.openPopup();
                  console.log(markerComp[k].id, "ini dia");

                  let headers = {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  };

                  API.get(`beranda/map/${markerComp[k].id}`, {headers})
                    .then(({data}) => {

                      console.log(data.data);
                      this.description.name = data.data.comp_name 
                      this.description.compliance_point_name = data.data.spot_name
                      this.description.type = "NONE" 
                      this.description.province = data.data.province_name 
                      this.description.regency = data.data.kabkot_name
                      this.description.address = data.data.comp_address
                      this.description.parameter = data.data.registered_parameter

                      this.description.beban_cod = data.data.beban_cod
                      this.description.beban_tss = data.data.beban_tss
                      this.description.beban_nh3n = data.data.beban_nh3n
                    })
                    .catch((err) => {
                      console.log(err);
                      Swal.fire('Gagal mendapatkan Maps','Maaf, Terjadi Kesalahan', 'error')
                    })
                  

                  this.showAnnoncementModalDescription = true;
              });
              
                
              mcg.addLayer(marker);
          }
        }

        map.addLayer(mcg);
    },
    // old
    // initMap(centerMap) {
    //   let dummyzoom = 5;

    //   if (this.log_as !== 'admin') {
    //     dummyzoom = 13
    //   } 

      

    //   let tmpMarker = [];
    //   for (let i = 0; i < this.mapData.length; i++) {
    //     tmpMarker.push({
    //       address: this.mapData[i].company_address,
    //       connected_status: "Terkoneksi",
    //       coordinate: {
    //         lat: this.mapData[i].lintang,
    //         lng: this.mapData[i].bujur,
    //       },
    //       nama_titik_penaatan: this.mapData[i].nama_titik_penaatan,
    //       loggerid: this.mapData[i].logger_id,
    //       name: this.mapData[i].company_name,
    //       type: this.mapData[i].company_type_name,
    //       parameter: this.mapData[i].sensor_use,
    //       province: this.mapData[i].province_name,
    //       regency: this.mapData[i].regency_name,
    //       ph: this.mapData[i].ph.toFixed(5),
    //       cod: this.mapData[i].cod.toFixed(5),
    //       tss: this.mapData[i].tss.toFixed(5),
    //       nh3n: this.mapData[i].nh3n.toFixed(5),
    //       debit: this.mapData[i].debit.toFixed(5),
    //       beban_cod: this.mapData[i].beban_cod.toFixed(5),
    //       beban_tss:this.mapData[i].beban_tss.toFixed(5),
    //       beban_nh3n:this.mapData[i].beban_nhn.toFixed(5),
    //       uuid: i,
    //     });
    //   }

    //   let map,
    //     lat = centerMap.lat,
    //     lng = centerMap.lng,
    //     color = "#5e72e4";
    //   map = document.getElementById("map-custom");

    //   let centerLoc = new google.maps.LatLng(lat, lng);
    //   let mapOptions = {
    //     zoom: dummyzoom,
    //     center: centerLoc,
    //     gestureHandling: "greedy",
    //     mapTypeId: google.maps.MapTypeId.ROADMAP,
    //     scrollwheel: false,
    //   };

    //   map = new google.maps.Map(map, mapOptions);
    //   let blueIcon = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    //   let redIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
    //   let orangeIcon =
    //     "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
    //   let greenIcon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";

    //   var InfoWindows = new google.maps.InfoWindow({});
    //   //NEW LOGGER MAP
    //   let markerComp = tmpMarker;

    //   let compMarker = [];
    //   for (let k = 0; k < markerComp.length; k++) {
    //     let posComp = new google.maps.LatLng(
    //       markerComp[k].coordinate.lat,
    //       markerComp[k].coordinate.lng
    //     );

    //     if (markerComp[k].connected_status != "Terkoneksi") {
    //       compMarker[k] = new google.maps.Marker({
    //         icon: { url: redIcon },
    //         position: posComp,
    //         map: map,
    //         // animation: google.maps.Animation.DROP,
    //         compID: markerComp[k].uuid,
    //         title: markerComp[k].name,
    //       });
    //     } else {
    //       console.log(markerComp[k],'INI MASTER');
    //       compMarker[k] = new google.maps.Marker({
    //         icon: { url: greenIcon },
    //         position: posComp,
    //         map: map,
    //         // animation: google.maps.Animation.DROP,
    //         compID: markerComp[k].uuid,
    //         // fill data here
    //         loggerid: markerComp[k].loggerid,
    //         nama_titik_penaatan: markerComp[k].nama_titik_penaatan,
    //         title: markerComp[k].name,
    //         type: markerComp[k].type,
    //         address: markerComp[k].address,
    //         province: markerComp[k].province,
    //         regency: markerComp[k].regency,
    //         parameter: markerComp[k].parameter,
    //         ph: markerComp[k].ph,
    //         cod: markerComp[k].cod,
    //         tss: markerComp[k].tss,
    //         debit: markerComp[k].debit,
    //         nh3n: markerComp[k].nh3n,
    //         beban_cod: markerComp[k].beban_cod,
    //         beban_tss: markerComp[k].beban_tss,
    //         beban_nh3n: markerComp[k].beban_nh3n
    //       });
    //     }

    //     // hover content and title setting
    //     // compMarker[k].addListener("click", function () {
    //     //   InfoWindows.open(map, this);
    //     //   InfoWindows.setContent(
    //     //     `<div id="content"><div id="siteNotice"></div><h3 id="firstHeading" class="firstHeading">${markerComp[k].name}</h3><div id="bodyContent"><p><b>Alamat : </b> ${markerComp[k].address}</p> <p>${markerComp[k].province} - ${markerComp[k].regency}</p><p><b>Status : </b>${markerComp[k].connected_status}</p><p><b>Parameter : </b>${markerComp[k].parameter}</p></div></div>`
    //     //   );
    //     // });
    //     // end hover content and title setting

    //     compMarker[k].addListener("click", () => {
    //       console.log(compMarker[k]);
    //       // console.log(compMarker[k].compID);
    //       this.showAnnoncementModalDescription = true;
    //       console.log(compMarker[k].title, "clicked");
    //       console.log(compMarker[k], "clicked");
    //       // Fill Data here
    //       this.description.loggerId = compMarker[k].loggerid 
    //       console.log('s',compMarker[k]);
    //       this.description.compliance_point_name = compMarker[k].nama_titik_penaatan,
    //       this.description.name = compMarker[k].title;
    //       this.description.type = compMarker[k].type;
    //       this.description.address = compMarker[k].address;
    //       this.description.province = compMarker[k].province;
    //       this.description.regency = compMarker[k].regency;
    //       this.description.parameter = compMarker[k].parameter;
    //       this.description.ph = compMarker[k].ph;
    //       this.description.cod = compMarker[k].cod;
    //       this.description.debit = compMarker[k].debit;
    //       this.description.nh3n = compMarker[k].nh3n;
    //       this.description.tss = compMarker[k].tss;
    //       // beban
    //       this.description.beban_cod = compMarker[k].beban_cod;
    //       this.description.beban_tss = compMarker[k].beban_tss;
    //       this.description.beban_nh3n = compMarker[k].beban_nh3n;

    //       // this.insertParamClickMarker(compMarker[k].compID, "comp");
    //     });
    //   }

    //   // CREATE PROV MARKER
    //   // var centerControlDiv = document.createElement("div");
    //   // var centerControl = new this.CenterControl(centerControlDiv, map, this);

    //   // centerControlDiv.index = 1;
    //   // map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

    //   // var provMarker = [];
    //   // var kabkotMarker = [];
    //   // var compMarker = [];

    //   // if (this.mapData.prov.length > 0) {
    //   //   for (let i = 0; i < this.mapData.prov.length; i++) {
    //   //     let pos = new google.maps.LatLng(
    //   //       this.mapData.prov[i].latlong.lat,
    //   //       this.mapData.prov[i].latlong.lng
    //   //     );
    //   //     provMarker[i] = new google.maps.Marker({
    //   //       icon: { url: orangeIcon },
    //   //       position: pos,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       provID: this.mapData.prov[i]._id,
    //   //       title: this.mapData.prov[i].provName
    //   //     });
    //   //     provMarker[i].addListener("click", () => {
    //   //       this.insertParamClickMarker(provMarker[i].provID, "prov");
    //   //       let provCenter = new google.maps.LatLng(
    //   //         this.mapData.prov[i].latlong.lat,
    //   //         this.mapData.prov[i].latlong.lng
    //   //       );
    //   //       map.setZoom(10);
    //   //       map.setCenter(provCenter);
    //   //       removeMarker(provMarker);
    //   //       let markerKabkot = this.mapData.kabkot.filter(data => {
    //   //         return data.provID === provMarker[i].provID;
    //   //       });
    //   //       for (let j = 0; j < markerKabkot.length; j++) {
    //   //         let posKabkot = new google.maps.LatLng(
    //   //           markerKabkot[j].latlong.lat,
    //   //           markerKabkot[j].latlong.lng
    //   //         );

    //   //         kabkotMarker[j] = new google.maps.Marker({
    //   //           icon: { url: blueIcon },
    //   //           position: posKabkot,
    //   //           map: map,
    //   //           // animation: google.maps.Animation.DROP,
    //   //           kabkotID: markerKabkot[j]._id,
    //   //           title: markerKabkot[j].kabkotName
    //   //         });
    //   //         kabkotMarker[j].addListener("click", () => {
    //   //           this.insertParamClickMarker(kabkotMarker[j].kabkotID, "kabkot");
    //   //           map.setZoom(14);
    //   //           map.setCenter(posKabkot);
    //   //           removeMarker(kabkotMarker);
    //   //           let markerComp = this.mapData.comp.filter(data => {
    //   //             return data.kabkotID === kabkotMarker[j].kabkotID;
    //   //           });
    //   //           for (let k = 0; k < markerComp.length; k++) {
    //   //             let posComp = new google.maps.LatLng(
    //   //               markerComp[k].latlong.lat,
    //   //               markerComp[k].latlong.lng
    //   //             );
    //   //             compMarker[k] = new google.maps.Marker({
    //   //               icon: { url: greenIcon },
    //   //               position: posComp,
    //   //               map: map,
    //   //               // animation: google.maps.Animation.DROP,
    //   //               compID: markerComp[k]._id,
    //   //               title: markerComp[k].compName
    //   //             });
    //   //             compMarker[k].addListener("click", () => {
    //   //               this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //             });
    //   //           }
    //   //         });
    //   //       }
    //   //     });
    //   //   }
    //   // } else if (this.mapData.kabkot.length > 0) {
    //   //   for (let j = 0; j < this.mapData.kabkot.length; j++) {
    //   //     let posKabkot = new google.maps.LatLng(
    //   //       this.mapData.kabkot[j].latlong.lat,
    //   //       this.mapData.kabkot[j].latlong.lng
    //   //     );

    //   //     kabkotMarker[j] = new google.maps.Marker({
    //   //       icon: { url: blueIcon },
    //   //       position: posKabkot,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       kabkotID: this.mapData.kabkot[j]._id,
    //   //       title: this.mapData.kabkot[j].kabkotName
    //   //     });
    //   //     kabkotMarker[j].addListener("click", () => {
    //   //       this.insertParamClickMarker(kabkotMarker[j].kabkotID, "kabkot");
    //   //       map.setZoom(14);
    //   //       map.setCenter(posKabkot);
    //   //       removeMarker(kabkotMarker);
    //   //       let markerComp = this.mapData.comp.filter(data => {
    //   //         return data.kabkotID === kabkotMarker[j].kabkotID;
    //   //       });
    //   //       for (let k = 0; k < markerComp.length; k++) {
    //   //         let posComp = new google.maps.LatLng(
    //   //           markerComp[k].latlong.lat,
    //   //           markerComp[k].latlong.lng
    //   //         );
    //   //         compMarker[k] = new google.maps.Marker({
    //   //           icon: { url: greenIcon },
    //   //           position: posComp,
    //   //           map: map,
    //   //           // animation: google.maps.Animation.DROP,
    //   //           compID: markerComp[k]._id,
    //   //           title: markerComp[k].compName
    //   //         });
    //   //         compMarker[k].addListener("click", () => {
    //   //           this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //         });
    //   //       }
    //   //     });
    //   //   }
    //   // } else if (this.mapData.comp.length > 0) {
    //   //   for (let k = 0; k < this.mapData.comp.length; k++) {
    //   //     let posComp = new google.maps.LatLng(
    //   //       this.mapData.comp[k].latlong.lat,
    //   //       this.mapData.comp[k].latlong.lng
    //   //     );
    //   //     compMarker[k] = new google.maps.Marker({
    //   //       icon: { url: greenIcon },
    //   //       position: posComp,
    //   //       map: map,
    //   //       // animation: google.maps.Animation.DROP,
    //   //       compID: this.mapData.comp[k]._id,
    //   //       title: this.mapData.comp[k].compName
    //   //     });
    //   //     compMarker[k].addListener("click", () => {
    //   //       this.insertParamClickMarker(compMarker[k].compID, "comp");
    //   //     });
    //   //   }
    //   // }

    //   // function insertMarker(markerName) {
    //   //   for (let i = 0; i < markerName.length; i++) {
    //   //     markerName[i].setMap(map);
    //   //   }
    //   // }

    //   // function removeMarker(markerName) {
    //   //   for (let i = 0; i < markerName.length; i++) {
    //   //     markerName[i].setMap(null);
    //   //   }
    //   // }
    // },
  },
};
</script>

<style>
.leaflet-popup-content {
  width: 550px !important;
}

.map-canvas {
  height: 550px;
}

.mdl-contentsannouncement {
  background: #ffc2c2;
  overflow-y: auto;
  max-height: 90%;
  padding: 20px;
}

.mdl-contentsannouncement-desc {
  background: #eaeaea;
  overflow-y: auto;
  max-height: 90%;
  padding: 20px;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #ffffff;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.summary-modal-backgrounds {
  background: #ff9999;
}

.chart-container {
  background-color: #63a070; /* Warna coklat */
  padding: 20px;
  border-radius: 10px;
  color:white;
  display: flex;
}
.apexcharts-text {
  fill: white;
}

</style>

<style lang="scss">
.parameter {
  height: 130px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .col-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    &:last-child {
      margin-right: 15px;
    }
  }
}
</style>
