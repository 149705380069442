<template>
    <div class="content">
      <div>
      <div>
      <b-col
          cols="12"
          class="mb-3 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
          <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          placeholder="Per page"
          >
          <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
          >
          </el-option>
          </el-select>
      </b-col>
      
  
      <el-table
          :data="queriedData"
          row-key="id"
          header-row-class-name="thead-light"
          @sort-change="sortChange"
          @selection-change="selectionChange"
      >

          <el-table-column min-width="180px" label="Nama Industri" v-if="role !== 'CL'">
            <div slot-scope="action">
              {{ action.row.company_name }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Jenis Industri" v-if="role !== 'CL'">
            <div slot-scope="action">
              {{ action.row.company_type_name }}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Provinsi" v-if="role !== 'CL'">
            <div slot-scope="action">
              {{ action.row.province_name}}
            </div>
          </el-table-column>

          <el-table-column min-width="180px" label="Kabupaten / Kota" v-if="role !== 'CL'">
            <div slot-scope="action">
              {{ action.row.regency_name }}
            </div>
          </el-table-column>



          <el-table-column min-width="180px"  label="Tanggal">
            <div slot-scope="action">
                {{ formatDate(action.row.date_time) }}
            </div>
          </el-table-column>

          

          <el-table-column min-width="180px"  label="Parameter">
            <div slot-scope="action">
                {{ action.row.description }}
            </div>
          </el-table-column>
  
          <el-table-column min-width="180px"  label="Surat Teguran">
            <div slot-scope="action">
               <a :href="action.row.file_reprimand" target="blank">Surat_teguran.pdf</a>
            </div>
          </el-table-column>
      </el-table>
      </div>
      <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
      <div class="">
          <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
  
          <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
          </span>
          </p>
      </div>
      <base-pagination
          class="pagination-no-border"
          :current="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          @change="paginationChanged($event)"
      >
      </base-pagination>
      </div>
      </div>
    </div>
  </template>
  <script>
  import { Table, TableColumn, Select, Option } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "./PaginatedTables/clientPaginationMixin";
  import swal from "sweetalert2";
  import moment from 'moment'
  
  export default {
    props: {
      tableData: {
        type: Array,
        required: true
      }
    },
    mixins: [clientPaginationMixin],
    components: {
      BasePagination,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        role : localStorage.getItem('as'),
        tableColumns: [
          // {
          //   prop: "nama_industri",
          //   label: "Nama Industri",
          //   minWidth: 180,
          //   sortable: true,
          // },
          // {
          //   prop: "jenis_industri",
          //   label: "Jenis Industri",
          //   minWidth: 180,
          // },
          // {
          //   prop: "provinsi",
          //   label: "Provinsi",
          //   minWidth: 150,
          // },
          // {
          //   prop: "kabkot",
          //   label: "Kab/Kot",
          //   minWidth: 150,
          // },
        ],
        selectedRows: [],
      };
    },
    methods: {
      formatDate(date) {
        return moment(date).format("DD/MM/YYYY HH:mm");
      },
      paginationChanged(page) {
        this.pagination.currentPage = page;
      },
      handleLike(index, row) {
        swal.fire({
          title: `You liked ${row.name}`,
          buttonsStyling: false,
          type: "success",
          confirmButtonClass: "btn btn-success btn-fill",
        });
      },
      handleEdit(index, row) {
        swal.fire({
          title: `You want to edit ${row.name}`,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info btn-fill",
        });
      },
      handleDelete(index, row) {
        swal
          .fire({
            title: "Are you sure?",
            text: `You won't be able to revert this!`,
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.value) {
              this.deleteRow(row);
              swal.fire({
                title: "Deleted!",
                text: `You deleted ${row.name}`,
                type: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            }
          });
      },
      deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
          (tableRow) => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
          this.tableData.splice(indexToDelete, 1);
        }
      },
      selectionChange(selectedRows) {
        this.selectedRows = selectedRows;
      },
      view(get) {
          this.$router.push("/pengumumanview");
          console.log("view");
      }
    },
  };
  </script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }
  </style>
  